// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  apiProtocol: 'https',
  apiBaseUrl: 'v2.api.sl8.app/api',
  stripePublicKey: 'pk_live_FKqwVqhM5tlhF4fA2nQqP1Cu00LsYrNyeK',

  // apiProtocol: 'http',
  // apiBaseUrl: 'localhost:8000/api',
  // stripePublicKey: 'pk_test_tebXPpFwek1XbXfnVcxh3QxZ004sjHmQis',
  // apiBaseUrl: 'ba66-24-182-56-50.ngrok.io/api',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
