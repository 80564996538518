<p>
    <mat-toolbar class="top-header">
        <div class="container" >
            <div class="tool-bar-container" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center" fxLayout.xs="column" fxLayoutAlign.xs="center center">
                <div class="header-contact-links" fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign.xs="center center">
                    <a href="tel:7754610906" class="top-header-links">
                        775-461-0906
                    </a>
                    <a href="email:customerservice@sl8homeservices.com" class="top-header-links">
                        CustomerService@SL8HomeServices.com
                    </a>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-evenly center" >
                    <a href="https://www.facebook.com/sl8home" class="social-media-links"><i class="fa-brands fa-facebook-f"></i></a>
                    <a href="https://www.instagram.com/sl8home" class="social-media-links"><i class="fa-brands fa-instagram"></i></a>
                    <a href="https://www.twitter.com/sl8home" class="social-media-links"><i class="fa-brands fa-twitter"></i></a>
                    <a href="https://www.youtube.com/channel/UCJiPhgqypylclqKXMqflBTQ" class="social-media-links"><i class="fa-brands fa-youtube"></i></a>
                </div>
            </div>
        </div>
    </mat-toolbar>
</p>

<p>
    <mat-toolbar class="top-sub-header">
        <div class="container">
            <div class="tool-bar-container">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <a href="https://sl8homeservices.com/" class="logo">
                        <img  src="./assets/images/sl8-logo-vector.png" style="height:inherit">
                    </a>
                    <!-- <div class="sub-header-links" fxLayout="row" fxLayoutAlign="space-between" fxHide.xs>
                        <div class="dropdown">
                            <a href="https://sl8homeservices.com/northern-nevada-house-cleaning/" id="rates-dropdown">
                                Rates
                            </a>
                            <div class="dropdown-content">
                                <a href="https://sl8homeservices.com/northern-nevada-house-cleaning/">Northen Nevada Regional Rates</a>
                            </div>
                        </div>
                        <a href="https://sl8homeservices.com/">
                            Home
                        </a>
                        <div class="dropdown">
                            <a href="" id="about-dropdown">
                                About
                            </a>
                            <div class="dropdown-content">
                                <a href="https://sl8homeservices.com/#faq-home">FAQ</a>
                                <a href="https://sl8homeservices.com/reviews/">Reviews</a>
                            </div>
                        </div>
                        <div class="dropdown">
                            <a href="https://sl8homeservices.com/#" id="servies-dropdown">
                                Services
                            </a>
                            <div class="dropdown-content">
                                <a href="#">
                                    Please create an account to see the services available in your area, these could include house cleaning, carpet cleaning, window cleaning and much more.
                                </a>
                            </div>
                        </div>
                        <a href="https://sl8homeservices.com/help/">
                            Help
                        </a>
                        <a href="https://sl8homeservices.com/contact" class="mr-20">
                            Contact
                        </a>
                        <a href="https://l.linklyhq.com/l/1UgYC" class="sign-up-button">
                            Sign Up Now
                        </a>
                    </div> -->
                </div>
            </div>
        </div>
    </mat-toolbar>
</p>

<router-outlet></router-outlet>

<div id="footer">
    <div class="container" fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign.gt-xs="space-between start" fxLayoutAlign.lt-md="center center">
        <div class="footer-column" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center">
            <h3>SL8 App</h3>
            <div class="footer-description" fxLayout="column" fxLayoutAlign.lt-md="center center">
                <p>Your source for residential and commercial cleaning<br> services</p>
                <a href="tel:7754610906">775-461-0906</a>
                <a href="email:customerservice@sl8homeservices.com">CustomerService@SL8HomeServices.com</a>
            </div>
        </div>
        <div class="footer-column" fxLayout="column" fxLayoutAlign.lt-md="center center">
            <h3>Sitemap</h3>
            <div class="footer-description" fxLayout="column" fxLayoutAlign.lt-md="center center">
                <a href="https://sl8homeservices.com/">Home</a>
                <a href="https://sl8homeservices.com/#faq-home">FAQs</a>
                <a href="https://sl8homeservices.com/reviews/">Reviews</a>
                <a href="https://sl8homeservices.com/terms-of-service/">Terms of Services</a>
            </div>
        </div>
        <div class="footer-column" fxLayout="column" fxLayoutAlign.lt-md="center center"
        [style.width.px]="165">
            <h3>Company</h3>
            <div class="footer-description" fxLayout="column" fxLayoutAlign.lt-md="center center">
                <a href="https://sl8homeservices.com/team/">Our Team</a>
                <a href="https://sl8homeservices.com/team">Contact</a>
                <a href="https://sl8homeservices.com/resources/">Resources</a>
                <a href="https://earnwithsl8.com/">Learn How to Earn with SL8 as a Contactor</a>
            </div>
        </div>
        <div class="footer-column" fxLayout="column" fxLayoutAlign.lt-md="center center">
            <h3>Download our App</h3>
            <div class="footer-description" fxLayout="column" fxLayoutAlign.lt-md="center center">
                <a href="https://l.linklyhq.com/l/1UgYC">
                    <img src="./assets/images/sl8-logo-vector.png" style="height:150px">
                </a>
            </div>
        </div>
    </div>
</div>